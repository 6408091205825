import React, { useEffect, useState } from 'react';
import { Row, Col, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { UseFormReturn } from 'react-hook-form';
import _ from 'lodash';

import { NumberOnlyField, SelectField } from 'src/components/form';
import {
  MACHINING_OPTIONS,
  CORNER_OPTIONS,
} from 'src/constants/quotation/optionSelect';
import { FormValue } from '../useForm';
import {
  getVisiableMachining,
  getVisiableCorner,
  getVisiableChamfer,
} from 'src/utils/quotation';
import { MACHINING_TOLERANCE } from 'src/constants/app';
import { checkMachiningOptions } from 'src/utils/helper';

type Props = {
  machiningMethod: string;
  formInfo: UseFormReturn<FormValue, any>;
  toleranceOptions: any;
  product: string;
};

const SizeInput: React.FC<Props> = ({
  machiningMethod,
  formInfo,
  toleranceOptions,
  product,
}) => {
  const { control, getValues, setValue } = formInfo;
  const { t } = useTranslation();

  const [showMachiningTolerance, setShowMachiningTolerance] = useState(false);

  const checkVisiableMachiningInitial = (tolerance) =>
    getVisiableMachining(getValues('shape'), machiningMethod, tolerance);

  const machiningThicknessOptions = checkMachiningOptions(
    product,
    toleranceOptions?.thickness,
    toleranceOptions?.apiCalled,
  );

  const machiningWidthOptions = checkMachiningOptions(
    product,
    toleranceOptions?.width,
    toleranceOptions?.apiCalled,
  );

  const machiningLengthOptions = checkMachiningOptions(
    product,
    toleranceOptions?.length,
    toleranceOptions?.apiCalled,
  );

  const machiningOutsideOptions = checkMachiningOptions(
    product,
    toleranceOptions?.outsideDia,
    toleranceOptions?.apiCalled,
  );

  const isVisiableMachining = (tolerance, machiningOptions) => {
    //before call API tolerance : checkVisiableMachiningInitial
    //after call API tolerance : check !_.isEmpty(machiningOptions)
    return (
      checkVisiableMachiningInitial(tolerance) &&
      (toleranceOptions?.apiCalled ? !_.isEmpty(machiningOptions) : true)
    );
  };

  const isVisiableMachiningThickness = isVisiableMachining(
    MACHINING_TOLERANCE.PLATE_THICKNESS,
    machiningThicknessOptions,
  );
  const isVisiableMachiningWidth = isVisiableMachining(
    MACHINING_TOLERANCE.WIDTH,
    machiningWidthOptions,
  );
  const isVisiableMachiningLength = isVisiableMachining(
    MACHINING_TOLERANCE.LENGTH,
    machiningLengthOptions,
  );
  const isVisiableMachiningOutside = isVisiableMachining(
    MACHINING_TOLERANCE.OUTSIDE_DIAMETER,
    machiningOutsideOptions,
  );
  const isVisiableMachiningInside = checkVisiableMachiningInitial(
    MACHINING_TOLERANCE.INSIDE_DIAMETER,
  );

  useEffect(() => {
    isVisiableMachiningThickness &&
      setValue(
        'machining_thickness',
        machiningThicknessOptions?.[0]?.value || '',
      );
    isVisiableMachiningWidth &&
      setValue('machining_width', machiningWidthOptions?.[0]?.value || '');
    isVisiableMachiningLength &&
      setValue('machining_length', machiningLengthOptions?.[0]?.value || '');
    isVisiableMachiningOutside &&
      setValue(
        'machining_outside_diameter',
        machiningOutsideOptions?.[0]?.value || '',
      );
    isVisiableMachiningInside &&
      setValue('machining_inside_diameter', MACHINING_OPTIONS[0].value);
  }, [toleranceOptions]);

  useEffect(() => {
    setShowMachiningTolerance(
      _.some([
        isVisiableMachiningThickness,
        isVisiableMachiningWidth,
        isVisiableMachiningLength,
        isVisiableMachiningOutside,
        isVisiableMachiningInside,
      ]),
    );
  }, [
    isVisiableMachiningThickness,
    isVisiableMachiningWidth,
    isVisiableMachiningLength,
    isVisiableMachiningOutside,
    isVisiableMachiningInside,
  ]);

  return (
    <Row>
      <Col xs={24} md={24} xl={12} className="options_size">
        {showMachiningTolerance && (
          <Row>
            <Col xs={24} sm={24} md={5} xl={6} className="form_typo">
              <Typography.Text className="form_label_no_required">
                {t('pricing_page.machining_tolerance.name')}
              </Typography.Text>
            </Col>
            <Col xs={24} sm={24} md={19} xl={18} className="options_size_input">
              <Row>
                {isVisiableMachiningThickness && (
                  <Col xs={24} md={24} xl={12}>
                    <SelectField<FormValue>
                      controller={{
                        control,
                        name: 'machining_thickness',
                      }}
                      selectProps={{
                        placeholder: t(
                          'pricing_page.machining_tolerance.plate_thickness',
                        ),
                        options: machiningThicknessOptions,
                      }}
                      label={t(
                        'pricing_page.machining_tolerance.plate_thickness',
                      )}
                      className="machining_select"
                    />
                  </Col>
                )}
                {isVisiableMachiningWidth && (
                  <Col xs={24} md={12} xl={12}>
                    <SelectField<FormValue>
                      controller={{ control, name: 'machining_width' }}
                      selectProps={{
                        placeholder: t(
                          'pricing_page.machining_tolerance.width',
                        ),
                        options: machiningWidthOptions,
                      }}
                      label={t('pricing_page.machining_tolerance.width')}
                      className="machining_select"
                    />
                  </Col>
                )}
                {isVisiableMachiningLength && (
                  <Col xs={24} md={12} xl={12}>
                    <SelectField<FormValue>
                      controller={{ control, name: 'machining_length' }}
                      selectProps={{
                        placeholder: t(
                          'pricing_page.machining_tolerance.length',
                        ),
                        options: machiningLengthOptions,
                      }}
                      label={t('pricing_page.machining_tolerance.length')}
                      className="machining_select"
                    />
                  </Col>
                )}
              </Row>
              <Row justify="space-between">
                {isVisiableMachiningOutside && (
                  <Col xs={24} md={11} xl={11}>
                    <SelectField<FormValue>
                      controller={{
                        control,
                        name: 'machining_outside_diameter',
                      }}
                      selectProps={{
                        placeholder: t(
                          'pricing_page.machining_tolerance.outside_diameter',
                        ),
                        options: machiningOutsideOptions,
                      }}
                      label={t(
                        'pricing_page.machining_tolerance.outside_diameter',
                      )}
                    />
                  </Col>
                )}
                {isVisiableMachiningInside && (
                  <Col xs={24} md={11} xl={11}>
                    <SelectField<FormValue>
                      controller={{
                        control,
                        name: 'machining_inside_diameter',
                      }}
                      selectProps={{
                        placeholder: t(
                          'pricing_page.machining_tolerance.inside_diameter',
                        ),
                        options: MACHINING_OPTIONS,
                      }}
                      label={t(
                        'pricing_page.machining_tolerance.inside_diameter',
                      )}
                    />
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        )}
        {getVisiableChamfer(getValues('shape'), machiningMethod, 0) &&
          getVisiableChamfer(getValues('shape'), machiningMethod, 1) && (
            <Row>
              <Col xs={24} sm={24} md={5} xl={6} className="form_typo">
                <Typography.Text className="form_label_no_required">
                  {t('pricing_page.around_chamfer.name')}
                </Typography.Text>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={19}
                xl={18}
                className="options_size_input"
              >
                <Row align="top" className="option_c">
                  {getVisiableChamfer(
                    getValues('shape'),
                    machiningMethod,
                    0,
                  ) && (
                    <Col xs={16} md={16} xl={14}>
                      <SelectField<FormValue>
                        controller={{
                          control,
                          name: 'options_size_unspecified',
                        }}
                        selectProps={{
                          placeholder: t(
                            'pricing_page.around_chamfer.unspecified',
                          ),
                          allowClear: true,
                          options: CORNER_OPTIONS(t),
                          defaultValue: CORNER_OPTIONS(t)[0].value,
                        }}
                      />
                    </Col>
                  )}
                  {getVisiableChamfer(
                    getValues('shape'),
                    machiningMethod,
                    1,
                  ) && (
                    <Col xs={8} md={6} xl={8} className="options_size_val">
                      <NumberOnlyField<FormValue>
                        controller={{ control, name: 'options_size_c' }}
                        inputProps={{
                          maxLength: 4,
                        }}
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 20 }}
                        label={t('pricing_page.around_chamfer.C')}
                      />
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          )}
      </Col>
      <Col xs={24} md={24} xl={12}>
        {getVisiableCorner(getValues('shape'), machiningMethod) && (
          <Row>
            <Col sm={24} md={8} xl={8} className="form_typo">
              <Typography.Text className="form_label_no_required">
                {t('pricing_page.corner_handling_options.name')}
              </Typography.Text>
            </Col>
            <Col span={24} className="box_corner">
              <Row
                justify="space-between"
                className="box_corner_container"
                align="top"
              >
                <Col xs={6} md={6} xl={6}>
                  <NumberOnlyField<FormValue>
                    controller={{ control, name: 'corner_option_a' }}
                    inputProps={{
                      maxLength: 4,
                    }}
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}
                    label={t('pricing_page.corner_handling_options.A')}
                    className="box_corner_first_input"
                  />
                  <NumberOnlyField<FormValue>
                    controller={{ control, name: 'corner_option_b' }}
                    inputProps={{
                      maxLength: 4,
                    }}
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}
                    label={t('pricing_page.corner_handling_options.B')}
                  />
                </Col>
                <Col xs={10} md={10} xl={10} className="box_corner_box">
                  <Row>
                    <Col className="box_corner_box_x">
                      <Typography.Text className="after_label_text">
                        {t('pricing_page.corner_handling_options.width')}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={3} md={2} className="box_corner_box_y">
                      <Typography.Text className="after_label_text">
                        {t('pricing_page.corner_handling_options.height')}
                      </Typography.Text>
                    </Col>
                    <Col xs={21} md={22}>
                      <div className="box_corner_preview"></div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={6} md={6} xl={6}>
                  <NumberOnlyField<FormValue>
                    controller={{ control, name: 'corner_option_c' }}
                    inputProps={{
                      maxLength: 4,
                    }}
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}
                    label={t('pricing_page.corner_handling_options.C')}
                    className="box_corner_first_input"
                  />
                  <NumberOnlyField<FormValue>
                    controller={{ control, name: 'corner_option_d' }}
                    inputProps={{
                      maxLength: 4,
                    }}
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}
                    label={t('pricing_page.corner_handling_options.D')}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default SizeInput;
