import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Popover, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { UseFormReturn } from 'react-hook-form';
import _ from 'lodash';

import useProducts from 'src/queries/useProducts';
import { FormValue } from '../useForm';
import { productFormat } from 'src/utils/product';
import ProductTooltip from 'src/components/common/ProductTooltip';
import { checkChangeLanguage, getProductName } from 'src/utils/helper';

type Props = {
  setProduct: (e) => void;
  product: string;
  formInfo: UseFormReturn<FormValue, any>;
  dataReEstimate?: Response.EstimatePriceDetail['item'];
  dataCategoryPage?: SearchParams.CategoryProductSearch;
  prevLang: string;
  lang: string;
  dataProductDimension?: Response.ProductDimension['item'];
};

const ProductList: React.FC<Props> = ({
  setProduct,
  product,
  formInfo,
  dataReEstimate,
  dataCategoryPage,
  prevLang,
  lang,
  dataProductDimension,
}) => {
  const { t } = useTranslation();
  const { setValue } = formInfo;
  const { products: listProduct } = useProducts();
  const [isFilled, setIsFilled] = useState(false);

  useEffect(() => {
    // auto fill value productId when navigate from Category page.
    if (dataCategoryPage?.productId && !isFilled) {
      setProduct(dataCategoryPage?.productId);
      setProductItem(dataCategoryPage?.productId);
      setIsFilled(true);
      return;
    }
    // auto fill value productId when ReEstimate from OrderHistory page
    if ((dataReEstimate?.hakudoShhnCd || dataReEstimate?.shhnCd) && !isFilled) {
      const productReEstimate = listProduct?.find((item) => {
        // split productId to compare with value from data re-estimate.
        const productSplit = productFormat.splitProductId(item.productId);
        return (
          (dataReEstimate?.hakudoShhnCd &&
            dataReEstimate?.hakudoShhnCd === productSplit.hakudoShhnCd) ||
          (dataReEstimate?.shhnCd &&
            dataReEstimate?.shhnCd === productSplit.shhnCd)
        );
      });
      if (productReEstimate) {
        setProduct(productReEstimate?.productId);
        setProductItem(productReEstimate?.productId);
        setIsFilled(true);
      }
      return;
    }
    // auto fill value productId when back from ProductDetail Page
    if (
      (dataProductDimension?.shhnCd ||
        dataProductDimension?.hakudoShhnCd ||
        dataProductDimension?.api1ShhnCd ||
        dataProductDimension?.api2ShhnCd) &&
      !isFilled
    ) {
      const productDetail = listProduct?.find((item) => {
        // split productId to compare with value from data product detail.
        const productSplit = productFormat.splitProductId(item.productId);
        if (dataProductDimension?.shhnCd) {
          return dataProductDimension.shhnCd === productSplit.shhnCd;
        }
        if (dataProductDimension?.hakudoShhnCd) {
          return (
            dataProductDimension.hakudoShhnCd === productSplit.hakudoShhnCd
          );
        }
        if (dataProductDimension?.api1ShhnCd) {
          return dataProductDimension.api1ShhnCd === productSplit.api1ShhnCd;
        }
        if (dataProductDimension?.api2ShhnCd) {
          return dataProductDimension.api2ShhnCd === productSplit.api2ShhnCd;
        }
        return false;
      });
      if (productDetail) {
        setProduct(productDetail?.productId);
        setProductItem(productDetail?.productId);
        setIsFilled(true);
      }
      return;
    }
    const value = _.get(listProduct, '[0].productId');
    setProduct(value);
    setProductItem(value);
  }, [
    _.get(listProduct, '[0].productId'),
    dataProductDimension,
    dataReEstimate,
  ]);

  const onSelectProduct = (e) => {
    const { value } = e.target;
    setProduct(value);
    setProductItem(value);
  };

  const setProductItem = (value) => {
    const productItem = listProduct?.find(
      (item) =>
        productFormat.mergeProductId(
          item.hakudoShhnCd,
          item.shhnCd,
          item.api1ShhnCd,
          item.api2ShhnCd,
        ) == value,
    );
    setValue('productItem', productItem);
  };

  useEffect(() => {
    // select first product when change language
    if (checkChangeLanguage(prevLang, lang)) {
      const value = _.get(listProduct, '[0].productId');
      setProduct(value);
      setProductItem(value);
    }
  }, [lang]);

  return (
    <Row>
      <Col xs={24} sm={24} md={4} xl={3} className="form_typo product_field">
        <Typography.Text className="form_label_required">
          {t('pricing_page.products_field')}
        </Typography.Text>
      </Col>
      <Col xs={24} sm={24} md={20} xl={21}>
        <Radio.Group
          className="w-full product_checkbox"
          onChange={onSelectProduct}
          value={product}
        >
          {listProduct?.map((item, index) => {
            return (
              <Popover
                placement="bottomLeft"
                key={index}
                content={
                  <ProductTooltip
                    product={item}
                    isShowDetailProductLink={true}
                  />
                }
                trigger="hover"
              >
                <Radio className="mr-0 mb-24" value={item.productId}>
                  {getProductName(item, true)}
                </Radio>
              </Popover>
            );
          })}
        </Radio.Group>
      </Col>
      <Col
        xs={24}
        sm={24}
        md={{ span: 20, offset: 4 }}
        xl={{ span: 21, offset: 3 }}
        className="product_field_description"
      >
        <Typography.Text>{t('pricing_page.description')}</Typography.Text>
      </Col>
    </Row>
  );
};

export default ProductList;
